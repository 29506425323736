import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Not found' />
    <div className="container mx-auto px-6 py-24 lg:py-32 text-center">
      <h1 className="font-bebas-neue uppercase text-6xl sm:text-8xl font-black leading-none dark:text-white text-gray-800">NOT FOUND</h1>
      <p className="font-bebas-neue text-2xl sm:text-4xl font-black leading-none dark:text-white text-gray-800">You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
)

export default NotFoundPage
